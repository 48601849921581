<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        serverId: this.data.serverId,
        login: this.data.login,
        order: this.data.order,
        cmd: this.data.cmd,
        symbol: this.data.symbol,
        volume: this.data.volume,
        signalServerId: this.data.signalServerId,
        signalLogin: this.data.signalLogin,
        signalOrder: this.data.signalOrder,
        signalSymbol: this.data.signalSymbol,
        signalVolume: this.data.signalVolume,
        signalCmd: this.data.signalCmd,
        tradeCmd: this.data.tradeCmd,
        tradeComment: this.data.tradeComment,
        status: this.data.status,
        reason: this.data.reason,
        requestId: this.data.requestId,
        subscriptionId: this.data.subscriptionId,
        messageRetryId: this.data.messageRetryId,
        retryCount: this.data.retryCount,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
