<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.order" clearable placeholder="订单id" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.signalLogin" clearable placeholder="信号源login" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.login" clearable placeholder="跟随者login" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.userId" clearable placeholder="用户id" style="width: 150px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select class="align-top" clearable v-model="query.status" filterable placeholder="跟单状态" style="width: 150px;">
      <el-option
        v-for="item in statusOptoins"
        :key="item.keyValue"
        :value="item.keyValue"
        :label="item.key"
      >
      </el-option>
    </el-select>
    <el-select class="align-top" clearable v-model="query.tradeCmd" filterable placeholder="交易操作" style="width: 130px;">
      <el-option
        v-for="item in operOptoins"
        :key="item.keyValue"
        :value="item.keyValue"
        :label="item.key"
      >
      </el-option>
    </el-select>
    <el-select class="align-top" clearable v-model="query.platformName" filterable placeholder="经纪平台" style="width: 130px;">
      <el-option
        v-for="item in platformNames"
        :key="item"
        :value="item"
        :label="item"
      >
      </el-option>
    </el-select>
    <el-date-picker
      class="align-top"
      style="width: 150px;"
      v-model="query.startTime"
      type="date"
      placeholder="开始日期">
    </el-date-picker>
    至
    <el-date-picker
      class="align-top"
      style="width: 150px;"
      v-model="query.endTime"
      type="date"
      placeholder="结束日期">
    </el-date-picker>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import { initData } from '@/api/data'
import eForm from './form'
export default {
  components: { eForm,searchCommunity },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      statusOptoins:[
        {key:'成功',keyValue:0},
        {key:'失败',keyValue:1}
      ],
      operOptoins:[
        {key:'开仓',keyValue:1},
        {key:'平仓',keyValue:2},
        {key:'自主平仓',keyValue:3},
        {key:'取消跟随',keyValue:4}
      ],
      platformNames:[]
    }
  },
  created(){
    this.initPlatform()
  },
  methods: {
    checkPermission,
    initPlatform(){
      initData('/community/crm/getDistinctSrPlatformInfo').then(res => {
        this.platformNames=res;
        }).catch(err => {
        })
    },
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
<style scoped lang="less">
  .align-top {
    vertical-align: top;
  }
</style>
