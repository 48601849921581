<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="服务器ID">
        <el-input v-model="form.serverId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="账号ID">
        <el-input v-model="form.login" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="订单ID">
        <el-input v-model="form.order" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易类型，0-买 1-卖">
        <el-input v-model="form.cmd" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易品种">
        <el-input v-model="form.symbol" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易量,1=0.01手">
        <el-input v-model="form.volume" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源server">
        <el-input v-model="form.signalServerId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源login">
        <el-input v-model="form.signalLogin" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源order">
        <el-input v-model="form.signalOrder" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源symbol">
        <el-input v-model="form.signalSymbol" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源交易量,1=0.01手">
        <el-input v-model="form.signalVolume" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源交易类型，0-买 1-卖">
        <el-input v-model="form.signalCmd" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="信号源交易操作，1-开仓 2-平仓">
        <el-input v-model="form.tradeCmd" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="交易评论">
        <el-input v-model="form.tradeComment" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="跟单状态 0-成功 1-失败(风控策略) 2-失败(mt4交易失败) 3-失败(网路超时) 9-失败（其它原因）">
        <el-input v-model="form.status" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="失败原因">
        <el-input v-model="form.reason" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="请求ID">
        <el-input v-model="form.requestId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="订阅ID">
        <el-input v-model="form.subscriptionId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="消息重试ID">
        <el-input v-model="form.messageRetryId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="重试次数">
        <el-input v-model="form.retryCount" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-input v-model="form.createTime" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-input v-model="form.updateTime" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/tradeCopyRelation.js'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        serverId: '',
        login: '',
        order: '',
        cmd: '',
        symbol: '',
        volume: '',
        signalServerId: '',
        signalLogin: '',
        signalOrder: '',
        signalSymbol: '',
        signalVolume: '',
        signalCmd: '',
        tradeCmd: '',
        tradeComment: '',
        status: '',
        reason: '',
        requestId: '',
        subscriptionId: '',
        messageRetryId: '',
        retryCount: '',
        createTime: '',
        updateTime: ''
      },
      rules:{}
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        serverId: '',
        login: '',
        order: '',
        cmd: '',
        symbol: '',
        volume: '',
        signalServerId: '',
        signalLogin: '',
        signalOrder: '',
        signalSymbol: '',
        signalVolume: '',
        signalCmd: '',
        tradeCmd: '',
        tradeComment: '',
        status: '',
        reason: '',
        requestId: '',
        subscriptionId: '',
        messageRetryId: '',
        retryCount: '',
        createTime: '',
        updateTime: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
