<template>
  <!--`id` '主键ID',
  `relation_id` '跟单ID',
  `server_id` '服务器ID',
  `request_id` '请求id',
  `tran_type` '交易类型',
  `tran_cmd`  '交易指令 75-买 76-卖',
  `tran_order`  '订单id ',
  `tran_orderby` '订单Login',
  `tran_symbol`  '交易产品',
  `tran_volume` '交易量',
  `tran_price`  '交易价格',
  `tran_comment`  '备注',
  `ret_status`  '返回状态',
  `ret_message` '返回消息',
  `ret_order`  '返回订单ID',-->
  <el-dialog
    title="跟单交易详情"
    :visible="dialogVisible"
    width="70%"
    :before-close="handleClose"
    append-to-body
  >
    <el-table :data="dataTable" stripe style="width: 100%;">
      <el-table-column prop="relationId" label="跟单ID" />
      <el-table-column prop="serverId" label="服务器ID" />
      <el-table-column prop="requestId" label="请求id"  width="150"/>
      <el-table-column prop="tranCmd" label="交易类型">
        <template slot-scope="scope">
          <span>{{scope.row.tranCmd == 0 ? 'buy' : 'sell'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tranType" label="交易操作">
        <template slot-scope="scope">
          <span>{{scope.row.tranType == 75 ? '开仓' : '平仓'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="tranOrder" label="订单id" />
      <el-table-column prop="tranOrderby" label="订单账号" />
      <el-table-column prop="tranSymbol" label="交易产品" />
      <el-table-column prop="tranVolume" label="交易量" />
      <el-table-column prop="tranComment" label="备注" />
      <el-table-column prop="retStatus" label="返回状态">
        <!-- 0 成功  1 失败-->
        <template slot-scope="scope">
          <span>{{scope.row.retStatus == '0' ? '成功' : '失败'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="retMessage" label="返回消息" />
      <el-table-column prop="retOrder" label="返回订单ID" />
    </el-table>
  </el-dialog>
</template>

<script>
  export default {
    name: 'dialogBox',
    props:{
      dialogVisible:{
        default: false
      },
      dataTable:{
        type: Array,
        required: true
      }
    },
    methods: {
      handleClose() {
        this.$emit('close-dialog');
      },
      getTradeCmd(signalCmd){
        let text = '';
        switch (signalCmd) {
          case 1:
            text = '开仓';
            break;
          case 2:
            text = '平仓';
            break;
          case 3:
            text = '自主平仓';
            break;
          case 4:
            text = '解除绑定';
            break;
        }
      },
      getStatus(status){
        return status == 0 ? '失败' : '成功';
      }
    }
  }
</script>

<style scoped>

</style>
